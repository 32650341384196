<template>
    <div>
        <component :is="'style'">
            :root {
                --primary-color: {{ primaryColor }};
                --primary-hover-color: {{ primaryColor }};
                --background-color: {{ backgroundColor }};
            }
        </component>
        <Navbar />
        <div class="wrapper">
            <router-view />
            <Footer />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '../stores/shop'
    
    import Navbar from '@/components/Navbar'
    import Footer from '@/components/Footer'

    export default {
        metaInfo() {
            return {
                title: this.title,
                titleTemplate: '%s | Tixgo'
            }
        },
        data() { 
            return {
                title: null,
            }
        },
        components: {
            Navbar,
            Footer,
        },
        computed: {
            primaryColor() {
                return this.getShop().theme.primary_color ? this.getShop().theme.primary_color : '#CA355B';
            },
            backgroundColor() {
                return this.getShop().theme.background_color ? this.getShop().theme.background_color : '#F2F5F7';
            },
        },
        methods: {
            ...mapActions(useShopStore, ['getShop','setShop']),
            fetchShop: function() {
                this.$axios.get("https://api.tixgo.nl/host/" + this.$route.params.host)
                    .then( response => {
                        if (response.data) {
                            this.setShop(response.data);
                            this.title = this.getShop().organisation.name;
                        }
                    }
                ).catch((response) => {
                    response;
                    this.$router.push('/error');
                });
            },
        },
        created() {
            if (this.$route.fullPath != '/error') {
                this.fetchShop();
            }
        }
    }
</script>

<style scoped>
    .wrapper{
        background-color:var(--background-color);
    }
</style>