<template>
  <div>
    <component :is="'style'">
        :root {
            --primary-color: {{ primaryColor }};
            --primary-hover-color: {{ primaryColor }};
            --background-color: {{ backgroundColor }};
        }
    </component>
    <Navbar />
    <div class="wrapper">
      <b-container fluid>
        <b-row>
          <b-col md="5" lg="4" xl="3" class="d-none d-lg-flex">
            <b-card class="sidebar w-100">
              <ProfileSidebar :path="'/' + $route.params.host + '/account'" />
            </b-card>
          </b-col>
          <b-col><router-view /></b-col>
        </b-row>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
  
<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '../stores/shop'
    
    import Navbar from '@/components/Navbar'
    import Footer from '@/components/Footer'
    import ProfileSidebar from '@/shared/components/ProfileSidebar';

    export default {
        metaInfo() {
            return {
                title: this.title,
                titleTemplate: '%s | Tixgo'
            }
        },
        data() { 
            return {
                title: null,
            }
        },
        components: {
            Navbar,
            Footer,
            ProfileSidebar,
        },
        computed: {
            primaryColor() {
                return this.getShop().theme.primary_color ? this.getShop().theme.primary_color : '#CA355B';
            },
            backgroundColor() {
                return this.getShop().theme.background_color ? this.getShop().theme.background_color : '#F2F5F7';
            },
        },
        methods: {
            ...mapActions(useShopStore, ['getShop','setShop']),
            fetchShop: function() {
                this.$axios.get("https://api.tixgo.nl/host/" + this.$route.params.host)
                    .then( response => {
                        if (response.data) {
                            this.setShop(response.data);
                            this.title = this.getShop().organisation.name;
                        }
                    }
                ).catch((response) => {
                    response;
                    this.$router.push('/error');
                });
            },
        },
        created() {
            if (this.$route.fullPath != '/error') {
                this.fetchShop();
            }
        }
    }
</script>

<style scoped>
    .wrapper{
        background-color:var(--background-color);
    }
</style>
  
<style scoped>
.sidebar{
  border-radius:0px;
}
.wrapper{
    background-color:#F2F5F7;
}
</style>